import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {LocationOn} from '@styled-icons/material';
import {TravelBus} from '@styled-icons/zondicons';
import {Car} from '@styled-icons/boxicons-solid';
import withPageEntry from '../../withPageEntry';

function About(props) {
  const [dimension] = useOutlet('dimension');
  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  return (
    <Wrapper dimension={dimension} id="rev-AboutPage">
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="about-banner"
          src="/images/about-banner-1.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">Intention</div>
        <div className="title">初衷</div>
        <div className="carousel-wrapper" style={{marginTop: 20}}>
          <img
            alt="about"
            src="../../images/about3.jpg"
            style={{
              objectFit: 'cover',
              maxWidth:
                dimension.rwd === 'desktop'
                  ? (dimension.innerWidth - 200) / 2
                  : '100%',
              height:
                dimension.rwd === 'desktop'
                  ? (dimension.innerWidth - 200) / 2 / 1.5
                  : '66.6%',
              borderRadius: 20,
            }}
          />
          <div style={{flexBasis: 40}} />
          <div style={{flex: 1}}>
            <p className="content">
              {`建築空間，因”人”存在才能生氣蓬勃；
器材用具，因”人”需要才被善加利用；
體貼”人”的，不光是豪華建築、更非科技器材，
而是另一個為您著想柔軟的心。

青田悅創辦者，身為兩個精力無窮兒子的爹，
在歷經陪伴太太懷孕、生產及養育的過程中，
不捨母親孕育小孩的身心辛苦，以及體會生命降臨家庭的感動，
使其更積極於創立舒適、支持與關懷家庭的產後護理之家。

這位兼具耐心與活力的爸爸，
熱愛與孩子一同激盪與發想，
喜愛探索生命旅程中的驚奇，
因此希望能提供給家庭中的每個成員，
在青田悅的舒適與安全中，
盡情探索生命旅程中獨特的喜悅與精彩。
`}
            </p>
          </div>
        </div>
      </section>
      <section className="section-dark">
        <div className="subtitle centered">Story & Spirit</div>
        <div className="title centered">品牌故事與服務理念</div>
        <p
          className="content centered"
          style={{marginTop: 40}}>{`用旅行的心，共同探索這段獨特的育兒旅程
生活，是探索旅程的總和；家人，是一同體驗的旅伴；而愛，是這段旅程的精采。
 
我們相信，育兒是一段不停探索與學習的旅程，新生兒是爸媽的青鳥，新手父母又何嘗不是對寶寶一無所知的雛鳥呢?
青鳥故事隱喻著追求幸福的過程，而青田悅希望藉由此形象與寓意，來表達對生命與生活的期許與實踐。 
我們支持陪伴親子的孕育旅程。
我們尊重欣賞個體的獨一無二。
相信彼此一定能交織出無可取代的珍貴回憶
 
我們擁有簡單的信念，來陪你完成這一點都不簡單的生命任務。
  
貼近你心，讓我們體現青田悅團隊的真誠與溫暖。
歡迎入住，讓我們創造美好的回憶，在這重要的生命旅程裡。
在你初為人父母之時，
青田悅團隊給予你身體與心靈的支持與陪伴，
尊重並欣賞每一個生命旅程中的探索、驚奇與喜悅。
 
邀請你來青田悅，我們陪你探索新的自己`}</p>
      </section>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="about-story"
          src="../../images/品牌故事與服務理念.jpg"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section-dark">
        <div className="subtitle">TRANSPORTATION</div>
        <div className="title">交通資訊</div>

        <div className="carousel-wrapper">
          <div style={{flex: 1}}>
            <div className="pj-row">
              <LocationOn size={30} />
              <div style={{marginLeft: 12}}>
                <div className="title">青田悅位置</div>
                <p className="content" style={{marginTop: 20}}>
                  桃園市桃園區水岸二街200號
                </p>
              </div>
            </div>

            <div className="pj-row" style={{marginTop: 40}}>
              <TravelBus size={30} />
              <div style={{marginLeft: 12}}>
                <div className="title">大眾運輸</div>
                <p className="content" style={{marginTop: 20}}>
                  {`於台北捷運圓山站轉搭客運 777 台北- 經國轉運站 班車，
平時約10-20分鐘一班車，假日10-15分鐘一班，經國轉運站下車。`}
                </p>
              </div>
            </div>
            <div className="pj-row" style={{marginTop: 40}}>
              <Car size={30} />
              <div style={{marginLeft: 12}}>
                <div className="title">開車路線</div>
                <p className="content" style={{marginTop: 20}}>
                  {`【由台北市區出發】
國道一號南崁交流道接經國路至中心約10分。

【由桃園市區出發】
桃園中正路接經國路至中心約20分。

【由東部地區出發】
東部遊客走國道在南崁交流道接經國路至中心約10分。

【由中南部出發】
國道三號新店交流道至中心約25分。`}
                </p>
              </div>
            </div>
          </div>
          <div style={{flexBasis: 20}} />
          <div style={{flex: 1, backgroundColor: '#eee'}}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14460.576112685785!2d121.2969504!3d25.0291858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x628f661d3a3cec12!2z6Z2S55Sw5oKF55Si5b6M6K2355CG5LmL5a62!5e0!3m2!1szh-TW!2stw!4v1626147703883!5m2!1szh-TW!2stw"
              width="100%"
              height="100%"
              style={{border: 0}}
              allowFullScreen={true}
              loading="lazy"></iframe>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);

  & > .banner {
    width: 100%;
    height: 600px;
  }
`;

export default withPageEntry(About);
